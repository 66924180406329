<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>

        <router-link class="btn btn-outline-info" to="user-admin" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to User List
        </router-link>

    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card overflow-hidden">
                        <div class="text-center p-4 overlay-box" style="background-image: url(/assets/images/profile/cover.jpg);">
                            <img :src="profile.profile" width="100" class="img-fluid rounded-circle" alt="Profile">
                            <h3 class="mt-3 mb-0 text-white">{{ profile.first_name + ' ' + profile.last_name }}</h3>
                            <p class="text-white">
                                {{ profile.title }}
                            </p>
                        </div>
                        <div class="card-body">
                            <div class="row text-center">
                                <div class="col-6">
                                    <div class="bgl-primary rounded p-3">
                                        <h4 class="mb-0">{{ profile.email }}</h4>
                                        <small>Email</small>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="bgl-primary rounded p-3">
                                        <h4 class="mb-0">{{ profile.phone_number }}</h4>
                                        <small>Phone</small>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row justify-content-center text-center">
                                <div class="col-4">
                                    <div :class="profile.is_admin ? 'bgl-success rounded p-3' : 'bgl-danger rounded p-3'">
                                        <h5 class="mb-0">
                                            {{ profile.is_admin ? 'Admin' : 'Not Admin' }}
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-4" style="cursor: pointer;" @click="handleAutoApproveChange()">
                                    <div :class="profile.is_auto_approve ? 'bgl-success rounded p-3' : 'bgl-danger rounded p-3'">
                                        <h5 class="mb-0">
                                            {{ profile.is_auto_approve ? 'Auto Approve' : 'Not Auto Approve' }}
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-4" style="cursor: pointer;" v-b-modal.modal-setup-user>
                                    <div :class="profile.is_report_access ? 'bgl-success rounded p-3' : 'bgl-danger rounded p-3'">
                                        <h5 class="mb-0">
                                            {{ Object.keys(dataReportAccess).length > 0 && profile.is_report_access ? dataReportAccess.name + ' Report' : 'No Report Access' }}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer mt-0">
                            <!-- Created date: {{ profile.created_at | moment('dddd, DD-MM-YYYY') }} -->
                            <button class="btn btn-primary btn-lg btn-block" v-b-modal.modal-add>Add User Access</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Privilege</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-responsive-m">
                            <thead>
                                <tr>
                                    <th>Menu Name</th>
                                    <th>Module Name</th>
                                    <th>Component Name</th>
                                    <th>Access</th>
                                    <th class="text-center">Position</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(v, i) in data" :key="i">
                                    <td>
                                        <img :src="v.image_icon" class="rounded-circle mr-2" width="36" alt="Icon" />
                                        {{ v.menu_name }}</td>
                                    <td>{{ v.module_name }}</td>
                                    <td>{{ v.component_name }}</td>
                                    <td>
                                        <span class="badge bagde-md badge-info"> {{ v.action_string }}</span>
                                    </td>
                                    <td class="text-center">{{ v.position_menu }}</td>
                                    <td class="text-center">
                                        <button @click="deletePrivilege(v)" class="btn btn-sm btn-danger">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-add" ref="my-modal-add" title="Form Add Privilege" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" button-size="sm">
        <form @submit.prevent="handleSubmit">
            <div class="row">
                <div class="col-md-8">
                    <div class="form">
                        <div class="form-group">
                            <label class="text text-info">Module</label>
                            <v-select :options="modules" label="name" v-model="selected.module" @input="setSelectedModule"></v-select>
                        </div>
                        <div class="form-group">
                            <label class="text text-info">Menu</label>
                            <v-select :options="menus" label="name" v-model="selected.menu" @input="setSelectedMenu"></v-select>
                        </div>
                        <div class="form-group">
                            <label class="text text-info">Component</label>
                            <v-select :options="components" label="name" v-model="selected.component" @input="setSelectedComponent"></v-select>
                        </div>
                        <div class="form-group">
                            <label class="text text-info">Position Menu</label>
                            <input type="number" v-model.number="formInput.position_menu" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="col-md-4 justify-content-center">
                    <h5>Set Access: </h5>
                    <div class="form">
                        <div class="col">
                            <div class="custom-control custom-checkbox mb-3 check-xs">
                                <input type="checkbox" class="custom-control-input" checked id="customCheckBox1" value="view" v-model="formInput.action">
                                <label class="custom-control-label" for="customCheckBox1"> View </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-control custom-checkbox mb-3 check-xs">
                                <input type="checkbox" class="custom-control-input" checked id="customCheckBox2" value="create" v-model="formInput.action">
                                <label class="custom-control-label" for="customCheckBox2"> Create </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-control custom-checkbox mb-3 check-xs">
                                <input type="checkbox" class="custom-control-input" checked id="customCheckBox3" value="update" v-model="formInput.action">
                                <label class="custom-control-label" for="customCheckBox3"> Update </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-control custom-checkbox mb-3 check-xs">
                                <input type="checkbox" class="custom-control-input" checked id="customCheckBox4" value="delete" v-model="formInput.action">
                                <label class="custom-control-label" for="customCheckBox4"> Delete </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>

    <b-modal id="modal-setup-user" ref="my-setup-user" title="Form Setup Document Type User" size="md" centered no-close-on-esc no-close-on-backdrop @ok="handleSubmitFormSetupUser" button-size="sm">
        <form @submit.prevent="handleSubmitFormSetupUser">
            <div class="row">
                <div class="col-md-12">
                    <div class="form">
                        <div class="form-group">
                            <label class="text text-info">Document Type</label>
                            <v-select :options="documents" label="name" v-model="document" @input="setSelectedDocumentType"></v-select>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";

export default {
    name: "Privilege",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Data Privilege | %s',
    },
    data() {
        return {
            document: {},
            documents: [],
            modules: [],
            menus: [],
            components: [],
            data: [],
            profile: {},
            selected: {
                module: '',
                menu: '',
                component: ''
            },
            dataReportAccess: {},
            formInputReport: {
                id_user: this.$route.query.id,
                type_doc: '',
                name: ''
            },
            formInput: {
                id_user: this.$route.query.id,
                id_menu: '',
                id_component: '',
                id_module: '',
                position_menu: 1,
                action: ["delete", "update", "view", "create"]
            }
        };
    },

    async mounted() {
        await this.$store.getters.isAdminUserManagement || this.$router.push('/');
        await this.onLoad();
        this.getModule();
        this.getMenu();
        this.getDocumentAllow()
        this.getReportAccess()
    },
    methods: {
        async handleAutoApproveChange() {
            this.$swal.fire({
                title: 'Change Auto Approve State?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post('user-service/management/user/update-auto-approve', {
                            id_user: this.$route.query.id
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        });
                        if (resp.data.error === false) {
                            await this.onLoad()
                            await this.getReportAccess()
                            this.$swal.fire("Success", resp.data.message, "success");
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch("setLoading", false);
                    }

                }
            });
        },
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('/');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "user-service/management/user/access/list", {
                        id_user: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.data = resp.data.data;
                }

                const resp2 = await axios.post(
                    "user-service/auth/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp2.data.error === false) {
                    return this.profile = resp2.data.data;
                }

                return this.$router.push('user-admin');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getModule() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('/');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "user-service/management/module/list", {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.modules = resp.data.data;
                }

                return this.$router.push('user-admin');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getMenu() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('/');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "user-service/management/menu/list", {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.menus = resp.data.data;
                }

                return this.$router.push('user-admin');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getComponent(slug_name) {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('/');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "user-service/management/component/list?module=" + slug_name, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.components = resp.data.data;
                }

                return this.$router.push('user-admin');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async setSelectedModule(val) {
            this.selected.component = '';

            if (val === null) {
                this.formInput.id_component = '';
                this.formInput.id_module = ''
                return;
            }

            this.formInput.id_module = val.id
            this.getComponent(val.slug_name);
        },
        async setSelectedMenu(val) {
            if (val === null) {
                this.formInput.id_menu = '';
                return;
            }

            this.formInput.id_menu = val.id
        },
        async setSelectedComponent(val) {
            if (val === null) {
                this.formInput.id_component = '';
                return;
            }

            this.formInput.id_component = val.id
        },
        async handleOk() {
            try {
                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "user-service/management/user/access/create", this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.selected = {
                        module: '',
                        menu: '',
                        component: ''
                    };
                    this.formInput = {
                        id_user: this.$route.query.id,
                        id_menu: '',
                        id_component: '',
                        id_module: '',
                        position_menu: 1,
                        action: ["delete", "update", "view", "create"]
                    };
                    this.onLoad()
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async deletePrivilege(value) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.delete(
                            "user-service/management/user/access/delete", {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id_user: this.$route.query.id,
                                    id_user_access: value.id
                                }
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.onLoad();
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
        async setSelectedDocumentType(val) {
            this.formInputReport.name = ""
            this.formInputReport.type_doc = ""

            if (val === null) {
                return;
            }

            this.formInputReport.id_user = this.$route.query.id
            this.formInputReport.type_doc = val.type_doc
            this.formInputReport.name = val.name
        },
        async handleSubmitFormSetupUser() {
            if (this.formInputReport.type_doc == "") {
                return this.disableReportModule()
            } else if (Object.keys(this.document).length > 0 && this.profile.is_report_access === false) {
                this.enableReportModule()
            }

            try {
                if (!this.$route.query.id) {
                    return this.$router.push('/');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    'report-service/set-user-document-access', this.formInputReport, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.onLoad()
                    await this.getReportAccess()
                    return this.$swal.fire("Success", resp.data.message, "success");
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getReportAccess() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('/');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    `report-service/document/my-layer-report?id_user=${this.$route.query.id}`, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    if (this.profile.is_report_access) {
                        this.document = resp.data.data
                        return this.dataReportAccess = resp.data.data;
                    }
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        // return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                // this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getDocumentAllow() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('/');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    `report-service/document/allow`, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.documents = resp.data.data;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async disableReportModule() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('/');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    `user-service/management/user/set-user-report-module`, {
                        id_user: this.$route.query.id,
                        is_access: false
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.onLoad()
                    return this.$swal.fire("Success", resp.data.message, "success");
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async enableReportModule() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('/');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    `user-service/management/user/set-user-report-module`, {
                        id_user: this.$route.query.id,
                        is_access: true
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.onLoad()
                    return this.$swal.fire("Success", resp.data.message, "success");
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
    },
};
</script>
