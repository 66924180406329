<template>
<div>
    <b-modal id="modal-form" ref="my-modal-add" :title="title" size="xl" centered no-close-on-esc no-close-on-backdrop button-size="sm" @ok="handleOk" @hidden="$parent.handleCancel">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6 col-lg-3">
                    <label class="text text-info">Feature</label>
                    <b-form-checkbox v-model.number="formInput.is_admin" name="checkbox-1"> is admin </b-form-checkbox>
                    <b-form-checkbox v-model.number="formInput.is_auto_approve" name="checkbox-1"> is auto approve </b-form-checkbox>
                </div>
                <div class="form-group col-md-6 col-lg-5">
                    <label class="text text-info">First Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.first_name" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Last Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.last_name" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Email</label>
                    <input type="email" class="form-control" placeholder="Enter the email" required v-model="formInput.email" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Title</label>
                    <input type="text" class="form-control" placeholder="Enter the title" required v-model="formInput.title" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Date of birth</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.date_birth" initial-date="1990-01-01"></b-form-datepicker>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Phone Number</label>
                    <input type="text" class="form-control" placeholder="Enter the phone number" required v-model="formInput.phone_number" />
                </div>
                <div class="form-group col-md-6 col-lg-8">
                    <label class="text text-info">Password</label>
                    <input type="password" class="form-control" placeholder="Enter the password" required v-model="formInput.password" />
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "FormUser",
    data() {
        return {
            title: 'Add User',
            formInput: {
                last_name: "",
                first_name: "",
                email: "",
                title: "",
                date_birth: "",
                is_admin: false,
                is_auto_approve: false,
                password: "",
                phone_number: "",
            },
        };
    },
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                if (this.propsRow.id != undefined) {
                    this.title = 'Update User'
                    this.formInput = Object.assign({}, this.propsRow);
                } else {
                    this.title = 'Add User'
                    this.formInput = {
                        last_name: "",
                        first_name: "",
                        email: "",
                        title: "",
                        date_birth: "",
                        is_admin: false,
                        is_auto_approve: false,
                        password: "",
                        phone_number: "",
                    }
                }
            },
        },
    },
    methods: {
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "user-service/management/user/create",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide("modal-form");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.formInput = {
                        last_name: "",
                        first_name: "",
                        email: "",
                        title: "",
                        date_birth: "",
                        is_admin: false,
                        is_auto_approve: false,
                        password: "",
                        phone_number: "",
                    }
                    
                    this.$parent.handleCancel()
                    this.$parent.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}
</style>
