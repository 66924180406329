<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>

        <router-link class="btn btn-outline-info mr-2" to="user-admin" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to User List
        </router-link>
        <button class="btn btn-info" @click="addTeam" :disabled="!totalSelected">
            <span class="fa fa-plus mr-2"></span> Add to team
        </button>

    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card overflow-hidden">
                        <div class="text-center p-4 overlay-box" style="background-image: url(/assets/images/profile/cover.jpg);">
                            <img :src="profile.profile" width="100" class="img-fluid rounded-circle" alt="Profile" style="object-fit: cover;">
                            <h3 class="mt-3 mb-0 text-white">{{ profile.first_name}}</h3>
                            <p class="text-white">
                                {{ profile.title }}
                            </p>
                        </div>
                        <div class="card-body">
                            <div class="row text-center">
                                <div class="col-6">
                                    <div class="bgl-primary rounded p-3">
                                        <h4 class="mb-0">{{ profile.email }}</h4>
                                        <small>Email</small>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="bgl-primary rounded p-3">
                                        <h4 class="mb-0">{{ profile.phone_number }}</h4>
                                        <small>Phone</small>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row justify-content-center text-center">
                                <div class="col-4" v-if="profile.is_admin">
                                    <div class="bgl-success rounded p-3">
                                        <h4 class="mb-0">Admin</h4>
                                    </div>
                                </div>
                                <div class="col-4" v-if="profile.is_auto_approve">
                                    <div class="bgl-success rounded p-3">
                                        <h4 class="mb-0">Auto Approve</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 justify-content-center">
                                <div class="col-12">
                                    <h4 class="card-title ml-4 text-center">My Team</h4>
                                    <div class="pt-4 table-responsive">
                                        <table class="table" style="overflow: hidden">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Title</th>
                                                    <th>Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(value, index) in myteam" :key="index">
                                                    <td>
                                                        <div>
                                                            <img :src="value.profile" class="rounded-circle mr-2" width="36" height="36" style="object-fit: cover;" alt="Profile" />
                                                            <span class="w-space-no">{{value.first_name}} {{value.last_name}}</span>
                                                        </div>
                                                    </td>
                                                    <td>{{value.title}}</td>
                                                    <!-- <td>{{value.email}}</td> -->
                                                    <td>
                                                        <div class="text-center">
                                                            <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="deleteTeam(value.id)"><i class="fa fa-trash"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Data User</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All Column</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select>
                            </div>
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuery.page,
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button-group>
                                    <!-- <b-button title="Edit" variant="outline-info" size="sm" @click="propsEdit = props.row" v-b-modal.modal-form><i class="fa fa-pencil"></i></b-button> -->
                                    <b-button title="Add To Team" v-if="alreadyExist(props.row.id)" :variant="props.row.checked ? 'outline-warning' : 'outline-info'" size="sm" @click="handleButtonAddTeam(props.row)">
                                        <b-icon :icon="props.row.checked ? 'trash-fill' : 'plus'"></b-icon>
                                    </b-button>
                                </b-button-group>
                            </div>
                            <div v-else-if="props.column.field == 'first_name'">
                                <div>
                                    <img :src="props.row.profile" class="rounded-circle mr-2" width="36" height="36" style="object-fit: cover;" alt="Profile" />
                                    <span class="w-space-no">{{ props.row.first_name}}</span>
                                </div>
                            </div>
                            <div v-else-if="props.column.field == 'checkbox'">
                                <span class="w-space-no">{{ props.row.checked ? 'v' : '' }}</span>
                            </div>
                            <div v-else-if="props.column.field == 'is_admin'">
                                <span v-if="props.row.is_admin" class="btn btn-sm btn-success"> Yes </span>
                                <span v-else class="btn btn-sm btn-danger"> No </span>
                            </div>
                            <div v-else-if="props.column.field == 'is_auto_approve'">
                                <span v-if="props.row.is_auto_approve" class="btn btn-sm btn-success"> Yes </span>
                                <span v-else class="btn btn-sm btn-danger"> No </span>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

export default {
    name: "ManageTeam",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Team Management | %s',
    },
    data() {
        return {
            columns: [{
                    label: "Name",
                    field: "first_name",
                    hidden: false,
                },
                {
                    label: "Title",
                    field: "title",
                    hidden: false,
                },
                {
                    label: "Email",
                    field: "email",
                    hidden: false,
                },
                {
                    label: "Select",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
            data: [],
            profile: {},
            selected: {
                module: '',
                menu: '',
                component: ''
            },
            formInput: {},
            teamshelter: [],
            myteam: [],
            rows: [],
            propsEdit: {},
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
            },
        };
    },
    computed: {
        columnRender: function () {
            return this.columns.filter(i => i.field != 'action')
        },
        totalSelected: function () {
            if (this.rows.filter(v => v.checked).length > 0) {
                return true
            }

            return false
        }
    },
    async mounted() {
        await this.$store.getters.isAdminUserManagement || this.$router.push('/');
        await this.getTeam();
        await this.onLoad();
        // this.getMenu();
    },
    methods: {
        alreadyExist: function (id) {
            return this.myteam.find(value => value.id === id) === undefined ? true : false
        },

        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('/');
                }

                await this.$store.dispatch('setLoading', true);
                // let resp = await axios.post("user-service/management/user/list", this.paramQuery, {
                //     headers: {
                //         Authorization: localStorage.getItem("token") || "",
                //     },
                // });

                // if (resp.data.error === false) {
                //     this.totalRecords = resp.data.pagination.total;
                //     this.rows = resp.data.data.filter(v => v.id !== this.$route.query.id);
                // }

                const resp2 = await axios.post(
                    "user-service/auth/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp2.data.error === false) {
                    this.getData()
                    return this.profile = resp2.data.data;
                }

                return this.$router.push('user-admin');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getTeam() {
            try {
                const resp = await axios.get(
                    `user-service/auth/group/my-team?id_user=${this.$route.query.id}`, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.myteam = resp.data.data.users;
                }

            } catch (error) {
                if (typeof error.response.data != undefined) {
                    this.$toastr.e(
                        error.response.data.message,
                        "Request Team"
                    );
                }
                this.$toastr.e(error, "Request Team");
            }
        },

        async AddTeam(team) {

            let exist = false;

            for (let i = 0; i < this.teamshelter.length; i++) {
                if (this.teamshelter[i].id_user_team == team.id) {
                    exist = true;
                    break;
                }
            }

            if (exist === false) {
                this.teamshelter.push({
                    name: team.first_name,
                    title: team.title,
                    id_user_lead: this.$route.query.id,
                    id_user_team: team.id
                })
            } else {
                return this.$swal.fire("User already added", '', "warning");
            }
        },

        handleCancel() {
            this.propsEdit = {};
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });
            this.getData();
        },
        async getData() {
            try {
                let resp = await axios.post("user-service/management/user/list", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data.filter(v => v.id !== this.$route.query.id).map(value => {
                        return {
                            checked: false,
                            ...value
                        }
                    });
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async removeTeam(index) {
            this.teamshelter.splice(index, 1);
        },

        async handleButtonAddTeam(row) {
            this.rows.find(v => v.id == row.id).checked = !row.checked
            row.checked = !row.checked
        },

        async addTeam() {

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        await this.rows.filter(v => v.checked).map(val => {
                            return {
                                id_user_team: val.id,
                                id_user_lead: this.$route.query.id,
                            }
                        }).forEach(async element => {
                            axios.post(
                                    "user-service/auth/group/add-team", element, {
                                        headers: {
                                            Authorization: localStorage.getItem("token") || "",
                                        },
                                    }
                                )
                                .then(resp => {
                                    if (resp.data.error === false) {
                                        return this.$swal.fire("Success", resp.data.message, "success");
                                    }
                                    this.$swal.fire("Error", resp.data.message, "error");
                                }).catch(error => {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                });
                        });

                    } catch (error) {
                        console.log(error);
                        this.$swal.fire("Error", error.response.data.message, "error");
                    } finally {
                        this.$store.dispatch("setLoading", false);
                        this.getTeam()
                        this.onLoad();
                    }
                }
            });
        },
        async deleteTeam(id_user) {

            console.log(id_user)

            this.$swal.fire({
                title: 'Are you sure you want to delete this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        const resp = await axios.post(
                            "user-service/auth/group/delete-team", {
                                id_user_team: id_user,
                                id_user_lead: this.$route.query.id,
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.getTeam()
                            this.onLoad();
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
        // async handleOk() {
        //     try {
        //         await this.$store.dispatch('setLoading', true);
        //         const resp = await axios.post(
        //             "user-service/management/user/access/create", this.formInput, {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             this.$swal.fire("Success", resp.data.message, "success");
        //             this.selected = {
        //                 module: '',
        //                 menu: '',
        //                 component: ''
        //             };
        //             this.formInput = {
        //                 id_user: this.$route.query.id,
        //                 id_menu: '',
        //                 id_component: '',
        //                 id_module: '',
        //                 position_menu: 1,
        //                 action: ["delete", "update", "view", "create"]
        //             };
        //             this.onLoad()
        //         }

        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Request", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Request", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     } finally {
        //         this.$store.dispatch('setLoading', false);
        //     }
        // },
    },
};
</script>
