<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <div class="dropdown custom-dropdown mr-2">
            <button type="button" class="btn btn-outline-primary d-flex align-items-center svg-btn" data-toggle="dropdown" aria-expanded="false" style="border-color: #2f4cdd !important">
                <svg width="16" class="scale5" height="16" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16647 27.9558C9.25682 27.9856 9.34946 28.0001 9.44106 28.0001C9.71269 28.0001 9.97541 27.8732 10.1437 27.6467L21.5954 12.2248C21.7926 11.9594 21.8232 11.6055 21.6746 11.31C21.526 11.0146 21.2236 10.8282 20.893 10.8282H13.1053V0.874999C13.1053 0.495358 12.8606 0.15903 12.4993 0.042327C12.1381 -0.0743215 11.7428 0.0551786 11.5207 0.363124L0.397278 15.7849C0.205106 16.0514 0.178364 16.403 0.327989 16.6954C0.477614 16.9878 0.77845 17.1718 1.10696 17.1718H8.56622V27.125C8.56622 27.5024 8.80816 27.8373 9.16647 27.9558ZM2.81693 15.4218L11.3553 3.58389V11.7032C11.3553 12.1865 11.7471 12.5782 12.2303 12.5782H19.1533L10.3162 24.479V16.2968C10.3162 15.8136 9.92444 15.4218 9.44122 15.4218H2.81693Z" fill="#2F4CDD" />
                </svg>
                <span class="fs-16 ml-3">Toggle Colums</span>
                <i class="fa fa-angle-down scale5 ml-3"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
                <button v-for="(column, index) in columnRender" :key="index" @click="changeColumn(column)" :class="
              column.hidden
                ? 'btn btn-xs btn-outline-warning btn-block'
                : 'btn btn-xs btn-outline-secondary btn-block'
            ">
                    {{ column.label }}
                </button>
            </div>
        </div>
        &nbsp;
        <b-button variant="outline-info" v-b-modal.modal-form>
            <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Add User
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Data User</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All Column</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select>
                            </div>
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuery.page,
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button title="Additional Info" :to="'additional-info?id='+ props.row.id" variant="outline-info" size="sm">
                                        <i class="fa fa-search"></i>
                                    </b-button>
                                    <b-button title="Privilege" variant="outline-info" size="sm" :to="'privilege?id=' + props.row.id"><i class="fa fa-cog"></i></b-button>
                                    <b-button title="Manage Team" variant="outline-success" size="sm" :to="'manage-team?id=' + props.row.id">
                                        <b-icon-people-fill></b-icon-people-fill>
                                    </b-button>
                                    <b-button title="User Approval" variant="outline-warning" size="sm" v-b-modal.modal-approval @click="getUserListforApproval(props.row)">
                                        <b-icon-unlock></b-icon-unlock>
                                    </b-button>
                                </b-button-group>
                            </div>
                            <div v-else-if="props.column.field == 'first_name'">
                                <div>
                                    <img :src="props.row.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                    <span class="w-space-no">{{ props.row.first_name + ' ' + props.row.last_name }}</span>
                                </div>
                            </div>
                            <div v-else-if="props.column.field == 'is_admin'">
                                <span v-if="props.row.is_admin" class="btn btn-sm btn-success"> Yes </span>
                                <span v-else class="btn btn-sm btn-danger"> No </span>
                            </div>
                            <div v-else-if="props.column.field == 'is_auto_approve'">
                                <span v-if="props.row.is_auto_approve" class="btn btn-sm btn-success"> Yes </span>
                                <span v-else class="btn btn-sm btn-danger"> No </span>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
    <b-modal ok-title="OK" id="modal-approval" ref="my-modal-approval" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk">
        <template #modal-title>
            Approval based on menu for {{formInput.Firstname}} {{formInput.Lastname}}
        </template>
        <form @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-5">
                    <label class="text text-info">Choose Menu</label>
                    <v-select label="name" :options="dataMenu" :reduce="menu => menu.id" v-model="formInput.id_menu">
                    </v-select>
                </div>
                <div class="form-group col-md-3">
                    <label class="text text-info">Hierarki</label>
                    <v-select v-model="formInput.hierarki" :options="['1', '2', '3']">
                    </v-select>
                </div>
                <div class="form-group col-md-3">
                    <label class="text text-info">Superior</label>
                    <v-select v-model="formInput.superior" :options="['No', 'Yes']">
                    </v-select>
                </div>
                <div class="form-group col-md-1">
                    <br>
                    <button type="button" @click="saveApprovalWorkspace" class="btn btn-sm pull-right btn-info" id="btn-add-approve"> Save</button>
                </div>
            </div>
        </form>
        <hr class="pt-2 pm-2">
        <div>
            <h5>User can approve :</h5>
            <div class="row">
                <div class="text-center col-md-12" v-if="capabilitiesApprove.length == 0">
                    <h4>
                        <i class="fa fa-times-circle text-danger" style="font-size: 40px;"></i> <br>There is no access for approval
                    </h4>
                </div>
                <div v-else class="media review-box d-flex col-md-6" v-for="(val, index) in capabilitiesApprove" :key="index">
                    <div class="item-delete">
                        <span @click="deleteApproval(val.id, val.id_user)" class="notify-badge-delete">
                            X
                        </span>
                        <img class="me-3 img-fluid btn-rounded" width="55" :src="val.menu.image_icon" alt="Icon">
                    </div>
                    <div class="media-body">
                        <h4 class="mt-0 mb-0 ml-2 text-black">{{ val.menu.name }} <small class="badge badge-xs badge-rounded badge-success light font-w200" v-if="val.superior == 1">Superior</small></h4>
                        <ul class="review-meta mb-3 d-block d-sm-flex align-items-center">
                            <li class="ml-2"><small>Hierarki {{ val.hierarki }}</small></li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    </b-modal>
    <FormUser :props-row="{}" />
</div>
</template>

<script>
import axios from "axios";
import FormUser from "./FormUser"

export default {
    name: "UserAdmin",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Data User | %s',
    },
    components: {
        FormUser
    },
    computed: {
        columnRender: function () {
            return this.columns.filter(i =>
                i.field != 'action'
            )
        },
        filterShelterApproval: function () {
            return this.shelter_approval.filter(v => v.id !== this.formInput.id_user)
        }
    },
    data() {
        return {
            columns: [{
                    label: "Name",
                    field: "first_name",
                    hidden: false,
                },
                {
                    label: "Title",
                    field: "title",
                    hidden: false,
                },
                {
                    label: "Email",
                    field: "email",
                    hidden: false,
                },
                {
                    label: "Date of birth",
                    field: "date_birth",
                    hidden: false,
                },
                {
                    label: "Phone No",
                    field: "phone_number",
                    hidden: false,
                },
                {
                    label: "Admin",
                    field: "is_admin",
                    thClass: "text-center",
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Auto Approve",
                    field: "is_auto_approve",
                    thClass: "text-center",
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Created Date",
                    field: "created_at",
                    hidden: false,
                },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
            sales_users: [],
            rows: [],
            propsArea: {},
            totalRecords: 0,
            shelter_approval: [],
            formInput: {
                Firstname: '',
                Lastname: '',
                id_user: '',
                id_user_approver: '',
                id_menu: '',
                hierarki: '3',
                superior: 'No',
                tier_approver: 0,
            },
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
            },
            paramQueryApproval: {
                page: 1,
                limit: 9999,
                search: "",
                column: "",
                ascending: false,
            },
            capabilitiesApprove: [],
            dataMenu: []
        };
    },
    async mounted() {
        await this.$store.getters.isAdminUserManagement || this.$router.push('/');
        await this.getSalesUsers();
        await this.getData();
        this.getDataMenu();
    },
    methods: {
        handleCancel() {
            this.propsArea = {};
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });
            this.getData();
        },

        async saveApprovalWorkspace() {
            console.log(this.formInput);
            if (this.formInput.hierarki == '' || this.formInput.id_menu == '' || this.formInput.superior == '') {
                return this.$swal.fire("Error", 'Menu, Hierarki, dan Superior harus diisi', "error");
            }

            var dataForm = {
                ...this.formInput,
                superior: this.formInput.superior == 'No' ? false : true
            }

            try {
                let resp = await axios.post("user-service/management/superior-authorize/add", dataForm, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.refreshListCapabilitiesApproval(this.formInput.id_user)
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getData() {
            try {
                let resp = await axios.post("user-service/management/user/list", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async refreshListCapabilitiesApproval(id_user) {
            try {
                let resp = await axios.post("user-service/management/superior-authorize/list-by-user", {
                    id_user: id_user
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.formInput.id_menu = ''
                    this.formInput.hierarki = '3'
                    this.formInput.superior = 'No'
                    this.capabilitiesApprove = resp.data.data
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async deleteApproval(id, idUser) {
            if (!confirm('Yakin ingin menghapus approval ini ?')) return false;
            
            try {

                let resp = await axios.delete("user-service/management/superior-authorize/delete", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                    data: {
                        id: id
                    }
                });

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.refreshListCapabilitiesApproval(idUser)
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getUserListforApproval(row) {
            try {
                let resp = await axios.post("user-service/management/superior-authorize/list-by-user", {
                    id_user: row.id
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                this.formInput = {
                    Firstname: '',
                    Lastname: '',
                    id_user: '',
                    id_user_approver: '',
                    id_menu: '',
                    hierarki: '3',
                    superior: 'No',
                    tier_approver: 0,
                }

                if (resp.data.error === false) {
                    this.formInput.id_user = row.id;
                    this.formInput.Firstname = row.first_name;
                    this.formInput.Lastname = row.last_name;
                    // this.shelter_approval = resp.data.data;
                    this.capabilitiesApprove = resp.data.data
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async handleOk() {
            return true;
            // try {
            //     await this.$store.dispatch('setLoading', true);
            //     const resp = await axios.post(
            //         "user-service/management/approver/add", this.formInput, {
            //             headers: {
            //                 Authorization: localStorage.getItem("token") || "",
            //             },
            //         }
            //     );

            //     if (resp.data.error === false) {
            //         this.$swal.fire("Success", resp.data.message, "success");
            //         this.shelter_approval = [];
            //         this.formInput = {};
            //         this.getData()
            //     }

            // } catch (error) {
            //     if (error.response != undefined) {
            //         if (error.response.data != undefined) {
            //             if (error.response.data.error_auth != undefined) {
            //                 this.$swal.fire("Request", error.response.data.message, "error");
            //                 this.$store.dispatch("logOut").then(() => {
            //                     this.$router.push("/login");
            //                 });
            //                 return;
            //             }
            //             return this.$swal.fire("Request", error.response.data.message, "error");
            //         }
            //     }

            //     this.$sentry.captureException(error);
            //     this.$swal.fire("Error", error.message, "error");
            // } finally {
            //     this.$store.dispatch('setLoading', false);
            // }
        },


        async getDataMenu() {
            try {
                let resp = await axios.get(
                    'user-service/management/menu/list', {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.dataMenu = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Menu", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Menu", error.message, "error");
            }
        },

        async getSalesUsers() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get(
                    'user-service/auth/users-by-role?role=sales&is_access=1', {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.sales_users = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Users", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Sales Users", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },

        // async deleteApprover(row) {

        //     this.$swal.fire({
        //         title: 'Are you sure delete this data?',
        //         text: "You won't be able to revert this!",
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonColor: '#3085d6',
        //         cancelButtonColor: '#d33',
        //         confirmButtonText: 'Yes, delete it!'
        //     }).then(async (result) => {
        //         if (result.isConfirmed) {
        //             try {
        //                 await this.$store.dispatch("setLoading", true);
        //                 let resp = await axios.delete(
        //                     "dental-erp-service/category/destroy", {
        //                         headers: {
        //                             Authorization: localStorage.getItem("token") || "",
        //                         },
        //                         data: {
        //                             id: row.id
        //                         }
        //                     }
        //                 );

        //                 if (resp.data.error === false) {
        //                     this.$swal.fire("Success", resp.data.message, "success");
        //                     this.getData();
        //                 }

        //             } catch (error) {
        //                 if (error.response != undefined) {
        //                     if (error.response.data != undefined) {
        //                         if (error.response.data.error_auth != undefined) {
        //                             this.$swal.fire("Error", error.response.data.message, "error");
        //                             this.$store.dispatch("logOut").then(() => {
        //                                 this.$router.push("/login");
        //                             });
        //                             return;
        //                         }
        //                         return this.$swal.fire("Request ", error.response.data.message, "error");
        //                     }
        //                 }

        //                 this.$sentry.captureException(error);
        //                 this.$swal.fire("Error", error.message, "error");
        //             } finally {
        //                 await this.$store.dispatch("setLoading", false);
        //             }
        //         }
        //     });
        // },
    },
};
</script>
<style scoped>
.item-delete {
    position:relative;
    padding-top:0px;
    display:inline-block;
}
.notify-badge-delete{
    position: absolute;
    right:-5px;
    cursor: pointer;
    top:0px;
    background:red;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color:white;
    padding:5px 10px;
    font-size:11px;
}
</style>