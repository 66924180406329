<template>
<transition name="fade" mode="out-in" appear>
    <component :is="urlQuery"></component>
</transition>
</template>

<script>
// import axios from "axios";
import Dashboard from '../views/AdminModule/Dashboard'
import UserAdmin from '../views/AdminModule/User/UserAdmin'
import ModuleAdmin from '../views/AdminModule/Module/ModuleAdmin'
import MenuAdmin from '../views/AdminModule/Menu/MenuAdmin'
import Privilege from '../views/AdminModule/User/Privilege'
import ManageTeam from '../views/AdminModule/User/ManageTeam'
import AdditionalInfo from '../views/AdminModule/User/AdditionalInfo'
import ComponentAdmin from '../views/AdminModule/Component/ComponentAdmin'
import SpecialConditionAdmin from '../views/AdminModule/SpecialCondition/List'

export default {
    name: 'Admin',
    data() {
        return {
            menuAdmin: [{
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Management',
                        module_slug_name: 'management',
                        name: '/management/dashboard',
                        slug_name: '/management/dashboard'
                    },
                    menu: {
                        name: 'Dashboard',
                        slug_name: 'dashboard',
                        icon: 'flaticon-381-home',
                        image_icon: 'https://storages.solusidental.id/media/6328821611739794-dashboard-icon.png'
                    }
                },
                {
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Management',
                        module_slug_name: 'management',
                        name: '/management/user-admin',
                        slug_name: '/management/user_admin'
                    },
                    menu: {
                        name: 'User',
                        slug_name: 'user',
                        icon: 'flaticon-381-home',
                        image_icon: '/assets/icon/HRM.png'
                    }
                },
                {
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Management',
                        module_slug_name: 'management',
                        name: '/management/special-condition-admin',
                        slug_name: '/management/special_condication_admin'
                    },
                    menu: {
                        name: 'Special Condition',
                        slug_name: 'special_condition',
                        icon: 'flaticon-381-home',
                        image_icon: '/assets/icon/HRM.png'
                    }
                },
                {
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Management',
                        module_slug_name: 'management',
                        name: '/management/menu-admin',
                        slug_name: '/management/menu_admin'
                    },
                    menu: {
                        name: 'Menu',
                        slug_name: 'menu',
                        icon: 'flaticon-381-home',
                        image_icon: '/assets/icon/productivity-1.png'
                    }
                },
                {
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Management',
                        module_slug_name: 'management',
                        name: '/management/module-admin',
                        slug_name: '/management/module_admin'
                    },
                    menu: {
                        name: 'Module',
                        slug_name: 'module',
                        icon: 'flaticon-381-home',
                        image_icon: '/assets/icon/Travelonomic.png'
                    }
                },
                {
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Management',
                        module_slug_name: 'management',
                        name: '/management/component-admin',
                        slug_name: '/management/component_admin'
                    },
                    menu: {
                        name: 'Component',
                        slug_name: 'component',
                        icon: 'flaticon-381-home',
                        image_icon: '/assets/icon/Asset-Management.png'
                    }
                },
            ]
        }
    },
    components: {
        Dashboard,
        UserAdmin,
        ModuleAdmin,
        MenuAdmin,
        Privilege,
        ManageTeam,
        AdditionalInfo,
        ComponentAdmin,
        SpecialConditionAdmin,
    },
    async created() {
        await this.$store.dispatch('setMenu', this.menuAdmin);

        //     try {
        //         // if (!this.$store.getters.isMenuEmpty) {
        //         //     return false;
        //         // }
        //         await this.$store.dispatch('setLoading', true);
        //         const resp = await axios.get("user-service/auth/menu?module=warehouse", {
        //             headers: {
        //                 Authorization: localStorage.getItem("token") || "",
        //             },
        //         });
        //         if (resp.data.error === false) {
        //             this.listApplication = resp.data.data;
        //             await this.$store.dispatch('setMenu', resp.data.data);
        //             return;
        //         }
        //     } catch (error) {
        //         // if (typeof error.response.data != undefined) {
        //         //     return await this.$toastr.e(error.response.data.message, "Get Application List");
        //         // }
        //         // return await this.$toastr.e(error, "Get Application List");
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Get Application List", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Get Application List ", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     } finally {
        //         this.$store.dispatch("setApp");
        //         this.$store.dispatch('setLoading', false);
        //     }
    },
    computed: {
        urlQuery() {
            return this.$route.params.component || 'dashboard';
        }
    }
}
</script>
