<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Additional Info</h4>
                    </div>
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="AddType">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">Info Name</label>
                                <input type="text" class="form-control" v-model="formInput.name">
                            </div>
                            <div class="form-group col-md-6 col-lg-5">
                                <label class="text text-info">Select Type</label>
                                <v-select :options="listType" label="name_type" v-model="SelectedType" :filterable="false" :clearable="false" @input="handleValue"></v-select>
                            </div>
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">Value </label>
                                <input v-if="SelectedType.type_input == 'text'" placeholder="Insert value..." type="text" class="form-control" v-model="formInput.value">
                                <input v-else-if="SelectedType.type_input == 'date'" type="date" class="form-control" v-model="formInput.value">
                                <input v-else-if="SelectedType.type_input == 'datetime'" type="datetime-local" class="form-control" v-model="formInput.value">
                                <b-form-file v-else-if="SelectedType.type_input == 'image'" accept="image/*" size="lg" v-on:change="onFileChange"></b-form-file>
                                <input v-else-if="SelectedType.type_input == 'time'" type="time" class="form-control" v-model="formInput.value">
                                <input class="form-control" disabled v-else type="text">
                            </div>
                            <div class="form-group col-md-6 col-lg-1">
                                <label class="text text-white">----- </label>
                                <!-- <button class="btn btn-outline-info mb-1 mr-1" :disabled="sales_user == null" @click="AddSales(sales_user)"> Assign Sales </button> -->
                                <button class="btn btn-outline-info text-center"> Add Type </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card" v-if="TypeShelter.length > 0">
                    <div class="card-body">
                        <div class="form-row">
                            <div class="pt-4 table-responsive">
                                <table class="table success-table-bordered text-center">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Value</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(value, index) in TypeShelter" :key="index">
                                            <td>{{value.name_field}}</td>
                                            <td>{{value.type}}</td>
                                            <td v-if="value.type=='image'"><img width="100px" height="100px" :src="value.value_field" alt=""></td>
                                            <td v-else>{{value.value_field}}</td>
                                            <td>
                                                <div class="text-center">
                                                    <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="removeType(index)"><i class="fa fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button :disabled="TypeShelter.length == 0" class="btn btn-outline-success ml-3 float-right" @click="handleSubmit()"> Add Additional Info </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require("moment");

export default {
    name: "AdditionalInfo",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Additional Info | %s',
    },
    props: {
        pieces: {
            type: Array,
        },
    },
    data() {
        return {
            warehouse: [],
            listType: [],
            SelectedType: [],
            TypeShelter: [],
            formInput: {
                id_user: '',
                data: [],
                name: '',
                value: '',
            },
        };
    },

    async created() {
        this.getListType();
    },

    // computed: {
    //     filterProduct: function () {
    //         return this.products.filter(i => i.udpate_by == null)
    //     },
    // },

    methods: {
        async getListType() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get(
                    'user-service/management/my-account/list-type-allow', {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.listType = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Type", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Type", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async AddType() {
            if (this.SelectedType.type_input == 'datetime')
                this.formInput.value = moment(this.formInput.value).format('YYYY-MM-DD HH:mm:ss')

            if (this.formInput.name !== '' && this.SelectedType.length !== 0 && this.formInput.value !== '') {
                this.TypeShelter.push({
                    id_type: this.SelectedType.id,
                    name_field: this.formInput.name,
                    type: this.SelectedType.type_input,
                    value_field: this.formInput.value
                })
                this.formInput = {
                    name: '',
                    value: '',
                }
                this.SelectedType = []
            }
        },

        async onFileChange(e) {
            const files = e.target.files[0]
            if (!files) return;

            var reader = new FileReader();
            reader.readAsDataURL(files);
            reader.onload = () => {
                // this.formInput.value = reader.result.split(',')[1]
                this.formInput.value = reader.result
            };
        },

        async removeType(index) {
            this.TypeShelter.splice(index, 1);
        },

        handleValue() {
            this.formInput.value = '';
        },

        async handleSubmit() {

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            "user-service/management/my-account/add", {
                                id_user: this.$route.query.id,
                                data: this.TypeShelter
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.TypeShelter = [];

                            this.formInput = {
                                name: '',
                                value: '',
                            }
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Error ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.my-text-style>>>.v-text-field__slot input {
    color: white
}
</style>
