<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        &nbsp;
        <b-button variant="outline-info" v-b-modal.modal-form>
            <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Add Special Condition
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Data Special Condition</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6" v-for="(val, index) in data" :key="index">
                            <div :class="val.condition == 'more' ? 'widget-stat card bg-warning' : 'widget-stat card bg-info' ">
                                <div class="card-header border-0 pb-0">
                                    <b-button size="sm" variant="danger" @click="deleteSpecialCondition(val.id)">
                                        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                    </b-button>
                                </div>
                                <div class="card-body p-4">
                                    <div class="media">
                                        <span class="me-3">
                                            <i :class=" val.condition == 'more' ? 'fa fa-chevron-right' : 'fa fa-chevron-left' "></i>
                                        </span>
                                        <div class="media-body text-white text-end">
                                            Tier: {{ val.maximal_tier }}
                                            <h1 class="text-white">
                                                {{ val.nominal.toLocaleString('id-ID') }}    
                                            </h1>
                                            <p class="mb-1">{{ val.menu_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <b-pagination @change="handlePaginationClick" v-model="page" :total-rows="rows" size="lg" first-number last-number prev-text="Prev" next-text="Next" align="center" :per-page="limit"></b-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <b-modal id="modal-form" ref="my-modal-add" title="Add Special Condition" size="xl" centered no-close-on-esc no-close-on-backdrop button-size="sm" @ok="handleOk" @hidden="handleCancel">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="text text-info">Menu</label>
                    <v-select :options="menuList" label="name" :reduce="val => val.id" v-model="formInput.id_menu" :filterable="false" :clearable="false"></v-select>
                </div>
                <div class="form-group col-md-4">
                    <label class="text text-info">Condition</label>
                    <v-select :options="['more', 'less']" label="name" v-model="formInput.condition" :filterable="false" :clearable="false"></v-select>
                </div>
                <div class="form-group col-md-4">
                    <label class="text text-info">Maximal Tier</label>
                    <v-select :options="[1, 2, 3]" label="name" v-model="formInput.maximal_tier" :filterable="false" :clearable="false"></v-select>
                </div>
                <div class="form-group col-md-12 col-lg-12">
                    <label class="text text-info">Nominal</label>
                    <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model.number="formInput.nominal" />
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import { BPagination } from 'bootstrap-vue'

export default {
    name: "List",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Data Special Condition | %s',
    },
    components: {
        BPagination,
    },
    computed: {
        // columnRender: function () {
        //     return this.columns.filter(i =>
        //         i.field != 'action'
        //     )
        // },
    },
    data() {
        return {
            data: [],
            menuList: [],
            selectedMenu: {},
            rows: 0,
            page: 1,
            limit: 12,
            ascending: true,
            search: '',
            formInput: {
                id_menu: '',
                condition: 'more',
                nominal: 0,
                maximal_tier: 1
            }
        };
    },
    async mounted() {
        await this.$store.getters.isAdminUserManagement || this.$router.push('/');
        await this.getData();
        this.getDataMenu();
    },
    methods: {

        async handlePaginationClick(event) {
            this.page = parseInt(event)
            this.getData()
        },

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("user-service/management/special-condition", {
                    page: this.page,
                    limit: this.limit,
                    ascending: this.ascending,
                    search: this.search,
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    // this.totalRecords = resp.data.pagination.total;
                    // this.rows = resp.data.data;
                    this.rows = resp.data.pagination.total
                    this.data = resp.data.data
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getDataMenu() {
            try {
                let resp = await axios.get("user-service/management/menu/list", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.menuList = resp.data.data
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async deleteSpecialCondition(id) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.delete(
                            "user-service/management/special-condition/delete", {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id: id
                                }
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.getData();
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },

        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "user-service/management/special-condition/add",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide("modal-form");
                    this.$swal.fire("Success", resp.data.message, "success");

                    this.handleCancel();
                    this.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },

        async handleCancel() {
            this.formInput = {
                id_menu: '',
                condition: 'more',
                nominal: 0,
                maximal_tier: 1
            }
            this.selectedMenu = {}
        },

    },
};
</script>
<style scoped>
.text-end {
    text-align: right !important;
}
</style>