<template>
<div>
    <b-modal id="modal-form" ref="my-modal-add" :title="title" size="xl" centered no-close-on-esc no-close-on-backdrop button-size="sm" @ok="handleOk" @hidden="$parent.handleCancel">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info">Menu Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.name" />
                </div>
                
                <div class="form-group col-md-12">
                    <label class="text text-info">Icon</label>
                    <vue-dropify remove-icon="d-none" @upload="onFileChange($event, value)" accept="image/*" />
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueDropify from 'vue-dropify';

export default {
    name: "FormMenu",
    components: {
        'vue-dropify': VueDropify,
    },
    data() {
        return {
            title: 'Add Menu',
            formInput: {
                name: "",
                image_filename: "",
                image_content: "",
            },
        };
    },
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                if (this.propsRow.id != undefined) {
                    this.title = 'Update Menu'
                    this.formInput = Object.assign({}, this.propsRow);
                } else {
                    this.title = 'Add Menu'
                    this.formInput = {
                        name: "",
                        image_filename: "",
                        image_content: ""
                    }
                }
            },
        },
    },
    methods: {

        async onFileChange(e) {
            const files = e[0]
            if (!files) return;

            var reader = new FileReader();
            reader.readAsDataURL(files);
            reader.onload = () => {
                this.formInput.image_content = reader.result
                this.formInput.image_filename = e[0].name;
            }
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },

        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "user-service/management/menu/create",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide("modal-form");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.formInput = {
                        name: "",
                        image_filename: "",
                        image_content: ""
                    }

                    this.$parent.handleCancel()
                    this.$parent.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
