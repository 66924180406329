<template>
<div class="container-fluid">
    <MenuApp />
    <div v-if="this.$router.app._route.name != 'Management'" class="row">
        <!-- <div class="col-sm-12 col-lg-12 col-xl-12 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-body orders-summary">
                            <div class="widget-timeline-icon">
                                <div class="row align-items-center mx-0">
                                    <div class="col-md-12 px-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-lg-6 col-xl-6 col-md-6">
            <div class="row">
                <div class="col-lg-6 pl-1 pr-1">
                    <div class="widget-stat card">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/HRM.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{listUser.length}}</span>
                                    </h3>
                                    <p class="mb-0">Total User</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pl-1 pr-1">
                    <div class="widget-stat card">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Invoice.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{pendingTransaction.length}}</span>
                                    </h3>
                                    <p class="mb-0">Pending Transaction</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pl-1 pr-1">
                    <div v-for="(transaction, index) in lastTransaction" :key="index" class="widget-stat card">
                        <div class="card-body p-4">
                            <h4 class="card-title">Recent Product Ordered</h4>
                            <div v-for="(val, index) in transaction.carts" :key="index">
                                <img v-if="val.product.files != null" :src="val.product.files[0].file_url" width="150" height="150">
                                <p class="mt-1">{{val.product.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pl-1 pr-1">
                    <div v-for="(val, index) in topSelling" :key="index" class="widget-stat card">
                        <div class="card-body p-4">
                            <h4 class="card-title">Top Selling</h4>
                            <img v-if="val.files != null" :src="val.files[0].file_url" width="150" height="150">
                            <p class="mt-1">{{val.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import MenuApp from '../../components/MenuApp';
// import axios from "axios";
// import PieChart from '@/components/PieChart';

export default {
    name: "Dashboard",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Dashboard E-commerce | %s',
    },
    components: {
        MenuApp,
        // PieChart,
    },
    // data() {
    //     return {
    //         listUser: [],
    //         lastTransaction: [],
    //         pendingTransaction: 0,
    //         topSelling: [],
    //         paramQuery: {
    //             page: 1,
    //             limit: 1,
    //             search: "",
    //             column: "",
    //             ascending: false,
    //         },
    //         paramQuery2: {
    //             page: 1,
    //             limit: 999,
    //             search: "",
    //             column: "",
    //             ascending: false,
    //             status_paid: "unpaid",
    //             is_ecommerce: true
    //         },
    //     };
    // },
    async mounted() {
        await this.$store.dispatch("setApp");
        // await this.getData();
        // await this.getLastTransaction();
        // await this.getTopSelling();
        // await this.getPendingTransaction();
        // await this.getListUser();
    },
};
</script>
