<template>
<div>
    <b-modal id="modal-form" ref="my-modal-add" :title="title" size="xl" centered no-close-on-esc no-close-on-backdrop button-size="sm" @ok="handleOk" @hidden="$parent.handleCancel">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Module</label>
                    <v-select label="slug_name" :options="modules" v-model="formInput.module" @input="setSelectedModule"></v-select>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Component Name</label>
                    <input type="text" class="form-control" placeholder="Enter the component name" required v-model="formInput.name" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Slug Name</label>
                    <input type="text" class="form-control" placeholder="Enter the slug name" required v-model="formInput.slug_name" />
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "FormModule",
    data() {
        return {
            title: 'Add Component',
            formInput: {
                name: "",
                slug_name: "",
                module: "",
            },
            modules: []
        };
    },
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                if (this.propsRow.id != undefined) {
                    this.title = 'Update Component'
                    this.formInput = Object.assign({}, this.propsRow);
                } else {
                    this.title = 'Add Component'
                    this.formInput = {
                        name: "",
                        slug_name: "",
                        module: "",
                    }
                }
            },
        },
    },
    async mounted() {
        await this.getModules()
    },
    methods: {

        async getModules() {
            try {
                const resp = await axios.get(
                    'user-service/management/module/list', {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.modules = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Provinces", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Provinces ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
                return await this.$toastr.e(error, "Request Provinces");
            }
        },

        async setSelectedModule(value) {
            if (value == null) {
                this.formInput.module = ""
            }

            this.formInput.module = value.slug_name
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },

        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "user-service/management/component/create",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide("modal-form");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.formInput = {
                        name: "",
                        slug_name: "",
                        module: "",
                    }

                    this.$parent.handleCancel()
                    this.$parent.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
